<template>
    <div class="add_audit">
        <div v-if="!shenji_project_power.power.edit">-</div>
        <el-button size="small" type="primary" @click="open" class="button_78px"
                   v-if="shenji_project_power.power.edit">管理
        </el-button>
        <el-dialog :title="`${project_name||''}项目审计情况`" :visible.sync="show" width="500px"
                   @closed="close" append-to-body>
            <div class="content">
                <div class="file_box">
                    <fileListComp :file_list="value" @change="editNameBack" />
                </div>
                <div>
                    <el-button type="text" @click="updateFile">添加附件</el-button>
                </div>
            </div>
            <div class="button" v-if="false">
                <el-button class="button_88px" size="small" type="primary" @click="addAuditCheckAssess"
                           v-if="shenji_project_power.power.edit">保存
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import computedData from '@/mixins/computedData';
import api from '@/api/methods/index';
import myPublic from '@/utils/public';
import dingDisk from '@/utils/ding_disk';
import fileListComp from '@/components/file/index.vue'

export default {
    mixins: [computedData],
    props: ['project_id', 'audit', 'project_name'],
    components: {fileListComp},
    data() {
        return {
            type: 1,
            show: false,
            value: [],
            is_edit: false//是否修改过
        };
    },
    computed: {
        shenji_project_power() {
            return this.$store.state.user.current_user.shenji_project_power;
        }
    },
    methods: {
        open() {
            this.is_edit = false;
            this.show = true;
            this.value = [];
            if (this.audit && myPublic.isJsonString(this.audit)) {
                this.value = JSON.parse(this.audit);
            }
        },
        //上传附件
        updateFile() {
            dingDisk.dingDiskUpdate(res => {
                this.value = [...this.value, ...res.data];
                this.addAuditCheckAssess();
            });
        },
        //预览附件
        previewFile(file_info) {
            dingDisk.previewFile(file_info);
        },
        //修改名称后的附件数组
        editNameBack(file_list) {
            this.value = file_list;
            this.addAuditCheckAssess();
        },
        addAuditCheckAssess() {
            let param = {project_id: this.project_id, type: 1, value: JSON.stringify(this.value)};
            api.project.addAuditCheckAssess(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: `项目审计保存成功`,
                        type: 'success'
                    });
                    this.is_edit = true;
                    // this.$emit('refresh');
                    // this.show = false;
                }
            });
        },
        close() {
            this.show = false;
            this.value = '';
            if (this.is_edit) {
                this.$emit('refresh');
            }
        }
    }
};
</script>

<style scoped lang="less">
/deep/ div.el-dialog {
    max-height: 90vh;

    .el-dialog__body {
        flex: 1;
        min-height: 0;
        display: flex;
    }

    .content {
        padding: 10px 20px;
        flex: 1;
        min-height: 200px;
        overflow-y: overlay;

        .title {
            padding: 10px 0;
        }

        .el-textarea .el-textarea__inner {
            resize: none;
        }
    }

    .button {
        border: none !important;
    }
}
</style>